.chart {
    flex: 4;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;
    color: grey;
}

.title {
    margin-bottom: 20px;
}

.chartGrid {
    stroke: rgb(238, 230, 230);
}