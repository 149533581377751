

.dashboardContainer {
    display: flex;  
    flex-direction: column;
}
.dashboardTop {
 display: flex;
 align-items: center;
 justify-content: center;
 opacity: 0.5;
 font-size: 26px;
 padding-top: 10px;
}

.widgets {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    padding: 10px;
    gap: 10px; 
}

.widget-card {
    flex: 1;
}

.charts {
    display: flex;
    gap: 20px;
    padding: 5px 20px;
}

.listTitle {
    font-weight: 500;
    color: gray;
    margin-bottom: 15px;
}

.listContainer {
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 20px;
    margin: 20px;

    
}