.traderTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    padding: 0 5px;
}
.standardTitle {
    font-size: 24px;
}

.iconButton {
    cursor: pointer;
}

.circularProgress {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}