.widget {
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 10px;

    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 10px;
    height: 60px;
    width: 20%;
}

.left{
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.title {
    font-weight: bold;
    font-size: 12px;
    color: rgb(160, 160, 160)
}

.counter {
    font-size: 18px;
    font-weight: 500;
}

.link {
    width: max-content;
    font-size: 12;
    border-bottom: 1px solid gray;
}

.icon {
    font-size: 18px;
    padding: 5px;
    border-radius: 5px;
    align-self: flex-end;
}

.not-validated {
    color: orange;
}

.validated {
    color: green;
}

/* .percentage {
    display: flex;
    align-items: center;
    font-size: 14px;

    &.positive {
        color: green;
    }

    &.negative {
        color: red;
    }
} */